import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import '../styles/index.scss'
import Footer from './Footer'
import Header from './Header'

const Layout = ({ children, className, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div id='wrapper' className={className}>
      <Header siteTitle={data.site.siteMetadata?.title || 'Title'} location={location} />
      <main>{children}</main>
      <Footer location={location} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
