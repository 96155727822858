import { graphql, useStaticQuery } from 'gatsby'
import { first, isEmpty } from 'lodash'
import React, { useState } from 'react'
import NavigationLink from './NavigationLink'

const Header = ({ location }) => {
  const { allStoryblokEntry } = useStaticQuery(
    graphql`
      query HeaderQuery {
        allStoryblokEntry(filter: {full_slug: {eq: "global"}}) {
          nodes {
            content
          }
        }
      }
    `
  )

  const node = first(allStoryblokEntry.nodes)
  const headerElements = !isEmpty(node) ? JSON.parse(node.content).header_navigation : []

  const [open, setOpen] = useState(false)

  const handleNavigationClick = () => setOpen(false)

  return (
    <header className={open ? 'open' : ''}>
      <div className='navigation'>
        <div className='container-fluid'>
          <ul className='list-unstyled'>
            {headerElements.map(item => (
              <NavigationLink key={item._uid} item={item} onClick={handleNavigationClick} location={location} />
            ))}
          </ul>
        </div>
      </div>
      <button className='btn btn-primary' onClick={() => setOpen(prevState => !prevState)}>
        <ul className='buns'>
          <li className='bun' />
          <li className='bun' />
        </ul>
      </button>
    </header>
  )
}

export default Header
