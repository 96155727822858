import { Link } from 'gatsby'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

const NavigationLink = ({ item, onClick, location }) => {
  if (!item) return null

  const url = item.url.cached_url
  const baseUrl = location?.pathname !== '/' && url.startsWith('#') ? '/' : ''
  const className = url.startsWith('#') && 'bold'

  return (
    <li className={isEmpty(item.displayName) && 'mt-4'}>
      {item.url.linktype === 'story'
        ? (
          <Link
            to={`/${url}`}
            className={className}
            onClick={onClick}
          >
            {item.displayName}
          </Link>
          )
        : (
          <a
            href={`${baseUrl + url}`}
            className={className}
            onClick={onClick}
          >
            {item.displayName}
          </a>
          )}
    </li>
  )
}

NavigationLink.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
  location: PropTypes.any
}

export default NavigationLink
