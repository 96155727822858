import { graphql, useStaticQuery } from 'gatsby'
import { first, isEmpty } from 'lodash'
import React from 'react'
import NavigationLink from './NavigationLink'

const Footer = ({ location }) => {
  const { allStoryblokEntry } = useStaticQuery(
    graphql`
      query FooterQuery {
        allStoryblokEntry(filter: {full_slug: {eq: "global"}}) {
          nodes {
            content
          }
        }
      }
    `
  )

  const node = first(allStoryblokEntry.nodes)
  const footerElements = !isEmpty(node) ? JSON.parse(node.content).footer_navigation : []

  return (
    <footer>
      <div className='container'>
        <div className='row'>
          {footerElements.map(el => (
            <div key={el._uid} className='col-12 col-md'>
              <ul className='list-unstyled'>
                <NavigationLink item={el} location={location} />
                {el.items?.map(item => <NavigationLink key={item._uid} item={item} location={location} />)}
              </ul>
            </div>
          ))}
        </div>
        <img src='https://a.storyblok.com/f/126453/x/054fcfd6e5/gmm_logo_2c.svg' className='d-block mw-100' width='120' />
      </div>
    </footer>
  )
}

export default Footer
